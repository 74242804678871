import { Box } from '@rebass/grid'
import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../util/style'

const BlogContainer = ({
  children,
  borderTop,
  mb,
  py,
  pt,
  pb,
  mt,
  attachTop,
  attachBottom,
}) => {
  const paddingT = pt || ['60px', '100px']
  const paddingB = pb || ['60px', '100px']

  return (
    <Wrapper
      px={2}
      pt={attachTop ? [0] : paddingT}
      pb={attachBottom ? [0] : paddingB}
      mt={mt}
    >
      {borderTop && (
        <Separator px={2}>
          <Line />
        </Separator>
      )}
      {children}
    </Wrapper>
  )
}

export default BlogContainer

const Wrapper = styled(Box)`
  position: relative;
`

const Separator = styled(Box)`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`

const Line = styled(Box)`
  background-color: ${theme.colors.grey11};
  height: 1px;
`
